import { useSubscriptionForCluster } from "in_queue/contexts/ProjectDataContext";
import { ACTIVATED } from "in_queue/types/projectType";

// This hook must be rendered within the ProjectDataContext
export const useIsSubscriptionStatusActive = (): boolean => {
    const subscription = useSubscriptionForCluster();

    return (
        subscription !== undefined &&
        subscription !== "loading" &&
        subscription.status === ACTIVATED
    );
};
