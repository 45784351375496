import { RegionType } from "./regionType";

export const PJM_ALL_QUEUE_SCOPE = "pjm_all_queue";
export const PJM_ISA_ONLY_SCOPE_TYPE = "pjm_isa_scope";
export const PJM_2028_LOAD_SCOPE = "pjm_load_2028_scope";
export const MISO_2022_SCOPE_TYPE = "miso_2022_generators_included";
export const MISO_2022_REALISTIC_SCOPE_TYPE = "miso_2022_realistic";
export const MISO_2022_MTEP_SCOPE_TYPE = "miso_2022_mtep";
export const SPP_ALL_GENERATORS_SCOPE_TYPE = "spp_all_generators_scope";
export const SPP_DISIS_2023_01_PHASE_1_PQ_SCOPE_TYPE =
    "spp_disis_2023_01_phase_1_pq_scope";
export const ERCOT_2027_SCOPE_TYPE = "ercot_2027_scope";
export const ERCOT_LOAD_2028_SCOPE_TYPE = "ercot_load_2028_scope";
export const WECC_2029_HEAVY_SUMMER_SCOPE_TYPE = "wecc_2029_heavy_summer";
export const WECC_ALL_GENERATORS_SCOPE_TYPE = "wecc_all_generators";
export const WECC_LATE_STAGE_GENERATORS_SCOPE_TYPE =
    "wecc_late_stage_generators";
export const NYISO_2029_SCOPE_TYPE = "nyiso_2029_scope";
export const NYISO_CLASS_YEAR_2029_SCOPE_TYPE = "nyiso_class_year_2029";
export const TVA_2028_WITHOUT_PRIORS_SCOPE_TYPE = "tva_2028_without_priors";
export const TVA_2028_WITH_PRIORS_SCOPE_TYPE = "tva_2028_with_priors";
export const ISONE_2026_SUMMER_PEAK_SCOPE_TYPE = "isone_2026_summer_peak";
export const ISONE_2026_SUMMER_PEAK_ALL_QUEUED_GENERATORS_SCOPE_TYPE =
    "isone_2026_summer_peak_all_queued_generators";
export const SOCO_2028_SCOPE_TYPE = "soco_2028_scope";
export const DUKE_DISIS_2023_PHASE_2_SCOPE_TYPE =
    "DUKE_DISIS_2023_PHASE_2_SCOPE";

export const SCOPE_TYPES = [
    PJM_ALL_QUEUE_SCOPE,
    PJM_ISA_ONLY_SCOPE_TYPE,
    PJM_2028_LOAD_SCOPE,
    MISO_2022_SCOPE_TYPE,
    MISO_2022_REALISTIC_SCOPE_TYPE,
    MISO_2022_MTEP_SCOPE_TYPE,
    SPP_ALL_GENERATORS_SCOPE_TYPE,
    SPP_DISIS_2023_01_PHASE_1_PQ_SCOPE_TYPE,
    ERCOT_2027_SCOPE_TYPE,
    ERCOT_LOAD_2028_SCOPE_TYPE,
    WECC_2029_HEAVY_SUMMER_SCOPE_TYPE,
    WECC_ALL_GENERATORS_SCOPE_TYPE,
    WECC_LATE_STAGE_GENERATORS_SCOPE_TYPE,
    NYISO_2029_SCOPE_TYPE,
    NYISO_CLASS_YEAR_2029_SCOPE_TYPE,
    TVA_2028_WITHOUT_PRIORS_SCOPE_TYPE,
    TVA_2028_WITH_PRIORS_SCOPE_TYPE,
    ISONE_2026_SUMMER_PEAK_SCOPE_TYPE,
    ISONE_2026_SUMMER_PEAK_ALL_QUEUED_GENERATORS_SCOPE_TYPE,
    SOCO_2028_SCOPE_TYPE,
    DUKE_DISIS_2023_PHASE_2_SCOPE_TYPE
] as const;
export type ScopeType = (typeof SCOPE_TYPES)[number];

export const PJM_SCOPE_TYPE = [
    PJM_ALL_QUEUE_SCOPE,
    PJM_ISA_ONLY_SCOPE_TYPE,
    PJM_2028_LOAD_SCOPE
] as const;
export type PJMScopeType = (typeof PJM_SCOPE_TYPE)[number];

export const MISO_SCOPE_TYPE = [
    MISO_2022_SCOPE_TYPE,
    MISO_2022_REALISTIC_SCOPE_TYPE,
    MISO_2022_MTEP_SCOPE_TYPE
] as const;
export type MISOScopeType = (typeof MISO_SCOPE_TYPE)[number];

export const SPP_SCOPE_TYPE = [
    SPP_ALL_GENERATORS_SCOPE_TYPE,
    SPP_DISIS_2023_01_PHASE_1_PQ_SCOPE_TYPE
] as const;
export type SPPScopeType = (typeof SPP_SCOPE_TYPE)[number];

export const ERCOT_SCOPE_TYPE = [
    ERCOT_2027_SCOPE_TYPE,
    ERCOT_LOAD_2028_SCOPE_TYPE
] as const;
export type ERCOTScopeType = (typeof ERCOT_SCOPE_TYPE)[number];

export const WECC_SCOPE_TYPE = [
    WECC_2029_HEAVY_SUMMER_SCOPE_TYPE,
    WECC_ALL_GENERATORS_SCOPE_TYPE,
    WECC_LATE_STAGE_GENERATORS_SCOPE_TYPE
] as const;
export type WECCScopeType = (typeof WECC_SCOPE_TYPE)[number];

export const NYISO_SCOPE_TYPE = [
    NYISO_2029_SCOPE_TYPE,
    NYISO_CLASS_YEAR_2029_SCOPE_TYPE
] as const;
export type NYISOScopeType = (typeof NYISO_SCOPE_TYPE)[number];

export const TVA_SCOPE_TYPE = [
    TVA_2028_WITHOUT_PRIORS_SCOPE_TYPE,
    TVA_2028_WITH_PRIORS_SCOPE_TYPE
] as const;
export type TVAScopeType = (typeof TVA_SCOPE_TYPE)[number];

export const ISONE_SCOPE_TYPE = [
    ISONE_2026_SUMMER_PEAK_SCOPE_TYPE,
    ISONE_2026_SUMMER_PEAK_ALL_QUEUED_GENERATORS_SCOPE_TYPE
] as const;
export type ISONEScopeType = (typeof ISONE_SCOPE_TYPE)[number];

export const SOCO_SCOPE_TYPE = [SOCO_2028_SCOPE_TYPE] as const;
export type SOCOScopeType = (typeof SOCO_SCOPE_TYPE)[number];

export const DUKE_SCOPE_TYPE = [DUKE_DISIS_2023_PHASE_2_SCOPE_TYPE] as const;
export type DukeScopeType = (typeof DUKE_SCOPE_TYPE)[number];

export const isKnownScopeType = (type: string): type is ScopeType => {
    return SCOPE_TYPES.includes(type as ScopeType);
};

export const isKnownPJMScopeType = (type: ScopeType): type is PJMScopeType => {
    return PJM_SCOPE_TYPE.includes(type as PJMScopeType);
};

export const isKnownMISOScopeType = (
    type: ScopeType
): type is MISOScopeType => {
    return MISO_SCOPE_TYPE.includes(type as MISOScopeType);
};

export const isKnownSPPScopeType = (type: ScopeType): type is SPPScopeType => {
    return SPP_SCOPE_TYPE.includes(type as SPPScopeType);
};

export const isKnownERCOTScopeType = (
    type: ScopeType
): type is ERCOTScopeType => {
    return ERCOT_SCOPE_TYPE.includes(type as ERCOTScopeType);
};

export const isKnownWECCScopeType = (
    type: ScopeType
): type is WECCScopeType => {
    return WECC_SCOPE_TYPE.includes(type as WECCScopeType);
};

export const isKnownNYISOScopeType = (
    type: ScopeType
): type is NYISOScopeType => {
    return NYISO_SCOPE_TYPE.includes(type as NYISOScopeType);
};

export const isKnownTVAScopeType = (type: ScopeType): type is TVAScopeType => {
    return TVA_SCOPE_TYPE.includes(type as TVAScopeType);
};

export const isKnownISONEScopeType = (
    type: ScopeType
): type is ISONEScopeType => {
    return ISONE_SCOPE_TYPE.includes(type as ISONEScopeType);
};

export const isKnownSOCOScopeType = (
    type: ScopeType
): type is SOCOScopeType => {
    return SOCO_SCOPE_TYPE.includes(type as SOCOScopeType);
};

export const isKnownDukeScopeType = (
    type: ScopeType
): type is DukeScopeType => {
    return DUKE_SCOPE_TYPE.includes(type as DukeScopeType);
};

export const doesScopeMatchRegion = (
    scope: ScopeType,
    region: RegionType
): boolean => {
    const regionToScopeChecker: {
        [key in RegionType]: (scope: ScopeType) => boolean;
    } = {
        NYISO: isKnownNYISOScopeType,
        PJM: isKnownPJMScopeType,
        MISO: isKnownMISOScopeType,
        // CAISO isn't actually built in the app, so this is irrelevant.
        CAISO: () => true,
        SPP: isKnownSPPScopeType,
        ERCOT: isKnownERCOTScopeType,
        WECC: isKnownWECCScopeType,
        TVA: isKnownTVAScopeType,
        ISONE: isKnownISONEScopeType,
        SOCO: isKnownSOCOScopeType,
        DUKE: isKnownDukeScopeType
    };

    return regionToScopeChecker[region](scope);
};

export const convertToScopeType = (string: string): ScopeType => {
    if (isKnownScopeType(string)) {
        return string;
    }

    throw new Error("Unexpected scope type");
};

export const convertToPJMScopeType = (scope: ScopeType): PJMScopeType => {
    if (isKnownPJMScopeType(scope)) {
        return scope;
    }

    throw new Error("Unexpected PJM scope type");
};

export const convertToMISOScopeType = (scope: ScopeType): MISOScopeType => {
    if (isKnownMISOScopeType(scope)) {
        return scope;
    }
    throw new Error("Unexpected MISO scope type");
};

export const convertToSPPScopeType = (scope: ScopeType): SPPScopeType => {
    if (isKnownSPPScopeType(scope)) {
        return scope;
    }

    throw new Error("Unexpected SPP scope type");
};

export const convertToERCOTScopeType = (scope: ScopeType): ERCOTScopeType => {
    if (isKnownERCOTScopeType(scope)) {
        return scope;
    }

    throw new Error("Unexpected ERCOT scope type");
};

export const convertToWECCScopeType = (scope: ScopeType): WECCScopeType => {
    if (isKnownWECCScopeType(scope)) {
        return scope;
    }

    throw new Error("Unexpected WECC scope type");
};

export const convertToTVAScopeType = (scope: ScopeType): TVAScopeType => {
    if (isKnownTVAScopeType(scope)) {
        return scope;
    }

    throw new Error("Unexpected TVA scope type");
};

export const convertToISONEScopeType = (scope: ScopeType): ISONEScopeType => {
    if (isKnownISONEScopeType(scope)) {
        return scope;
    }

    throw new Error("Unexpected ISONE scope type");
};

export const convertToNYISOScopeType = (scope: ScopeType): NYISOScopeType => {
    if (isKnownNYISOScopeType(scope)) {
        return scope;
    }

    throw new Error("Unexpected NYISO scope type");
};

export const convertToDukeScopeType = (scope: ScopeType): DukeScopeType => {
    if (isKnownDukeScopeType(scope)) {
        return scope;
    }

    throw new Error("Unexpected Duke scope type");
};
