import {
    ControlGroup,
    FormGroup,
    NumericInput,
    Switch,
    Tooltip
} from "@blueprintjs/core";
import { useCluster, useRegionConfig } from "in_queue/contexts/ClusterContext";
import { useTrackedProject } from "in_queue/contexts/ProjectDataContext";
import { useState } from "react";
import { useParams } from "react-router-dom";

import css from "./ProjectSizeInput.module.scss";

type ProjectSizeInputProps = {
    erisSizeReduction: number;
    nrisSizeReduction: number;
    onChangeSizeReduction: (params: {
        type: "eris" | "nris" | "both";
        newSize: number;
    }) => void;
};

export const ProjectSizeInput: React.FC<ProjectSizeInputProps> = ({
    erisSizeReduction,
    nrisSizeReduction,
    onChangeSizeReduction
}: ProjectSizeInputProps) => {
    const { projectId } = useParams();
    const cluster = useCluster();
    const project = useTrackedProject({
        projectId,
        phase: cluster.studyPhase
    });

    const config = useRegionConfig();
    const [editSeparately, setEditSeparately] = useState(
        erisSizeReduction !== nrisSizeReduction
    );

    if (!project || project == "loading") {
        return <></>;
    }

    const toggleEditSeparately = () => {
        // When going from editing separately to editing both simultaneously, we want
        // to use the erisSizeReduction as the default value for both reductions.
        // On the contrary, updating the size reductions is not be needed when switching to
        // editing separately because both the erisSizeReduction and nrisSizeReduction will
        // already have the same value.
        const newEditSeparately = !editSeparately;
        if (!newEditSeparately) {
            onChangeSizeReduction({
                type: "both",
                newSize: erisSizeReduction
            });
        }
        setEditSeparately(newEditSeparately);
    };

    const isDisabled = !project.isEditable;

    const editSeparatelySwitch = (
        <Switch
            disabled={isDisabled}
            label={`Adjust ${config.sizeLabel.energyLabel} + ${config.sizeLabel.capacityLabel} separately`}
            checked={editSeparately}
            onChange={toggleEditSeparately}
        />
    );

    const projectSizeElement = editSeparately ? (
        <ControlGroup>
            <FormGroup
                label={`${config.sizeLabel.energyLabel} size reduction (%)`}
            >
                <NumericInput
                    disabled={isDisabled}
                    stepSize={1}
                    min={0}
                    max={100}
                    value={erisSizeReduction}
                    onValueChange={(newValue) => {
                        if (newValue >= 0 && newValue <= 100) {
                            onChangeSizeReduction({
                                type: "eris",
                                newSize: newValue
                            });
                        }
                    }}
                    required
                />
                {editSeparatelySwitch}
            </FormGroup>
            <FormGroup
                label={`${config.sizeLabel.capacityLabel} size reduction (%)`}
            >
                <NumericInput
                    disabled={isDisabled}
                    stepSize={1}
                    min={0}
                    max={100}
                    value={nrisSizeReduction}
                    onValueChange={(newValue) => {
                        if (newValue >= 0 && newValue <= 100) {
                            onChangeSizeReduction({
                                type: "nris",
                                newSize: newValue
                            });
                        }
                    }}
                    required
                />
            </FormGroup>
        </ControlGroup>
    ) : (
        <FormGroup
            label="Project size reduction (%)"
            className={css["iseditable-formgroup"]}
        >
            <NumericInput
                disabled={isDisabled}
                stepSize={1}
                min={0}
                max={100}
                value={erisSizeReduction}
                onValueChange={(newValue) => {
                    if (newValue >= 0 && newValue <= 100) {
                        onChangeSizeReduction({
                            type: "both",
                            newSize: newValue
                        });
                    }
                }}
                required
            />
            {editSeparatelySwitch}
        </FormGroup>
    );

    return (
        <Tooltip
            content="This project cannot be downsized."
            position="bottom"
            disabled={!isDisabled}
            className={css["iseditable-tooltip"]}
        >
            {projectSizeElement}
        </Tooltip>
    );
};
