import {
    ClusterId,
    clusterIdToCluster,
    InQueueCluster,
    isValidClusterId
} from "in_queue/types/clusterType";
import {
    ALL_DISABLED,
    InQueueRegionConfig,
    REGIONS_TO_COMPONENT_CONFIG,
    StageConfig
} from "in_queue/types/configPerRegion";
import { createContext, useContext } from "react";
import { useParams } from "react-router-dom";

interface ClusterValue {
    clusterId: ClusterId;
    cluster: InQueueCluster;
    regionConfig: InQueueRegionConfig;
    stageConfig: StageConfig;
}

export const ClusterContext = createContext<ClusterValue | undefined>(
    undefined
);

export const ClusterProvider: React.FC = ({ children }) => {
    const { clusterId } = useParams();
    if (!clusterId || !isValidClusterId(clusterId)) {
        throw new Error("ClusterId was not found in the url or was malformed");
    }
    const cluster = clusterIdToCluster(clusterId);
    const regionConfig = REGIONS_TO_COMPONENT_CONFIG[cluster.region];
    const stageConfig = regionConfig.availableClusters[cluster.studyCycle]?.[
        cluster.studyGroup
    ]?.[cluster.studyPhase] ?? {
        // This shouldn't ever actually happen
        phaseStatus: ALL_DISABLED,
        configurableCostEstimatesForPreview: false
    };

    return (
        <ClusterContext.Provider
            value={{ clusterId, cluster, regionConfig, stageConfig }}
        >
            {children}
        </ClusterContext.Provider>
    );
};

const useClusterValue = (): ClusterValue => {
    const context = useContext(ClusterContext);
    if (context === undefined) {
        throw new Error(
            "useClusterValue must be used within a ClusterProvider"
        );
    }

    return context;
};

export const useClusterId = (): ClusterId => {
    const value = useClusterValue();
    return value.clusterId;
};

export const useCluster = (): InQueueCluster => {
    const value = useClusterValue();
    return value.cluster;
};

export const useRegionConfig = (): InQueueRegionConfig => {
    const value = useClusterValue();
    return value.regionConfig;
};

export const useStageConfig = (): StageConfig => {
    const value = useClusterValue();
    return value.stageConfig;
};
