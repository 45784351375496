import {
    AllocatedUpgradesTableColumnId,
    ConstraintsTableColumnId,
    ScenarioProjectsTableColumnId,
    ScenariosTableColumnId
} from "in_queue/components/tables/agGrid/agGridHelpers";
import { RegionType } from "types/regionType";
import { StudyCycle, StudyGroup, StudyPhase } from "./clusterType";
import { ScenarioStage } from "./scenarioType";

type TableConfig<TColumn> = {
    columnsToRemove: readonly TColumn[];
};

type SizeLabel = {
    energyLabel: string;
    capacityLabel: string;
};

type LoadLabel = {
    benchLoad: string;
    studyLoad: string;
};

export type StageConfig = {
    phaseStatus: {
        readonly [key in ScenarioStage]: {
            enabled: boolean;
            createButtonEnabled: boolean;
        };
    };
    configurableCostEstimatesForPreview: boolean;
};

type AvailableClusters = {
    readonly [key in StudyCycle]?: {
        readonly [key in StudyGroup]?: {
            readonly [key in StudyPhase]?: StageConfig;
        };
    };
};

export const ALL_DISABLED = {
    PREVIEW: {
        enabled: false,
        createButtonEnabled: false
    },
    DECISION_POINT: {
        enabled: false,
        createButtonEnabled: false
    }
};

const PREVIEW_MODE = {
    PREVIEW: {
        enabled: true,
        createButtonEnabled: true
    },
    DECISION_POINT: {
        enabled: false,
        createButtonEnabled: false
    }
};

const DP_MODE = {
    PREVIEW: {
        enabled: true,
        createButtonEnabled: false
    },
    DECISION_POINT: {
        enabled: true,
        createButtonEnabled: true
    }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ONLY_DP_MODE = {
    PREVIEW: {
        enabled: false,
        createButtonEnabled: false
    },
    DECISION_POINT: {
        enabled: true,
        createButtonEnabled: true
    }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AFTER_DP = {
    PREVIEW: {
        enabled: true,
        createButtonEnabled: false
    },
    DECISION_POINT: {
        enabled: true,
        createButtonEnabled: false
    }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PREVIEW_BUT_NO_SCENARIOS = {
    PREVIEW: {
        enabled: true,
        createButtonEnabled: false
    },
    DECISION_POINT: {
        enabled: false,
        createButtonEnabled: false
    }
};

export type InQueueRegionConfig = {
    readonly scenariosTable: TableConfig<ScenariosTableColumnId>;
    readonly allocatedUpgradesTable: TableConfig<AllocatedUpgradesTableColumnId>;
    readonly constraintsTable: TableConfig<ConstraintsTableColumnId>;
    readonly scenarioProjectsTable: TableConfig<ScenarioProjectsTableColumnId>;
    readonly sizeLabel: SizeLabel;
    readonly loadLabel: LoadLabel;
    readonly availableClusters: AvailableClusters;
    readonly showScenarioPreview: boolean;
};

const DEFAULT_CONFIG: InQueueRegionConfig = {
    scenariosTable: { columnsToRemove: [] },
    allocatedUpgradesTable: { columnsToRemove: [] },
    constraintsTable: { columnsToRemove: [] },
    scenarioProjectsTable: { columnsToRemove: [] },
    sizeLabel: {
        energyLabel: "Energy",
        capacityLabel: "Capacity"
    },
    loadLabel: {
        benchLoad: "Bench",
        studyLoad: "Study"
    },
    availableClusters: {},
    showScenarioPreview: false
};

export const IN_QUEUE_REGIONS: readonly RegionType[] = ["PJM", "MISO", "SPP"];

export const REGIONS_TO_COMPONENT_CONFIG: {
    readonly [key in RegionType]: InQueueRegionConfig;
} = {
    NYISO: DEFAULT_CONFIG,
    PJM: {
        scenariosTable: { columnsToRemove: ["inputData", "costAssumptions"] },
        allocatedUpgradesTable: {
            columnsToRemove: [
                "maxDfax",
                "sumMwImpact",
                "pmax",
                "constraintType"
            ]
        },
        constraintsTable: {
            columnsToRemove: [
                "preStudyFlow",
                "postStudyFlow",
                "facilityDetails"
            ]
        },
        scenarioProjectsTable: {
            columnsToRemove: [
                "subregion",
                "erisThermal",
                "erisComplex",
                "nrisThermal",
                "nrisComplex",
                "erisSnu",
                "erisLpc"
            ]
        },
        sizeLabel: {
            energyLabel: "Energy",
            capacityLabel: "Capacity"
        },
        loadLabel: {
            benchLoad: "Pre-cluster",
            studyLoad: "Post-cluster"
        },
        availableClusters: {
            "Transition Cycle 1": {
                default_group: {
                    one: {
                        phaseStatus: DP_MODE,
                        configurableCostEstimatesForPreview: false
                    },
                    two: {
                        phaseStatus: PREVIEW_MODE,
                        configurableCostEstimatesForPreview: false
                    }
                }
            },
            "Transition Cycle 2": {
                default_group: {
                    resubmission: {
                        phaseStatus: PREVIEW_MODE,
                        configurableCostEstimatesForPreview: true
                    },
                    one: {
                        phaseStatus: ALL_DISABLED,
                        configurableCostEstimatesForPreview: false
                    },
                    two: {
                        phaseStatus: ALL_DISABLED,
                        configurableCostEstimatesForPreview: false
                    }
                }
            }
        },
        showScenarioPreview: false
    },
    MISO: {
        ...DEFAULT_CONFIG,
        sizeLabel: {
            energyLabel: "ERIS",
            capacityLabel: "NRIS"
        },
        allocatedUpgradesTable: {
            columnsToRemove: ["networkUpgradeId"]
        },
        availableClusters: {
            "DPP-2022-Cycle": {
                South: {
                    pre_screen: {
                        phaseStatus: AFTER_DP,
                        configurableCostEstimatesForPreview: true
                    },
                    one: {
                        phaseStatus: PREVIEW_MODE,
                        configurableCostEstimatesForPreview: true
                    },
                    two: {
                        phaseStatus: ALL_DISABLED,
                        configurableCostEstimatesForPreview: true
                    }
                },
                Central: {
                    one: {
                        phaseStatus: PREVIEW_MODE,
                        configurableCostEstimatesForPreview: true
                    },
                    two: {
                        phaseStatus: ALL_DISABLED,
                        configurableCostEstimatesForPreview: true
                    }
                },
                West: {
                    one: {
                        phaseStatus: PREVIEW_MODE,
                        configurableCostEstimatesForPreview: true
                    },
                    two: {
                        phaseStatus: ALL_DISABLED,
                        configurableCostEstimatesForPreview: true
                    }
                },
                Atc: {
                    one: {
                        phaseStatus: PREVIEW_MODE,
                        configurableCostEstimatesForPreview: true
                    },
                    two: {
                        phaseStatus: ALL_DISABLED,
                        configurableCostEstimatesForPreview: true
                    }
                },
                Itc: {
                    one: {
                        phaseStatus: PREVIEW_MODE,
                        configurableCostEstimatesForPreview: true
                    },
                    two: {
                        phaseStatus: ALL_DISABLED,
                        configurableCostEstimatesForPreview: true
                    }
                }
            },
            "DPP-2021-Cycle": {
                South: {
                    one: {
                        phaseStatus: AFTER_DP,
                        configurableCostEstimatesForPreview: true
                    },
                    two: {
                        phaseStatus: DP_MODE,
                        configurableCostEstimatesForPreview: true
                    }
                },
                Central: {
                    one: {
                        phaseStatus: DP_MODE,
                        configurableCostEstimatesForPreview: true
                    }
                },
                Atc: {
                    two: {
                        phaseStatus: DP_MODE,
                        configurableCostEstimatesForPreview: true
                    }
                }
            },
            "DPP-2020-Cycle": {
                Central: {
                    one: {
                        phaseStatus: ALL_DISABLED,
                        configurableCostEstimatesForPreview: true
                    },
                    two: {
                        phaseStatus: AFTER_DP,
                        configurableCostEstimatesForPreview: true
                    }
                }
            }
        },
        showScenarioPreview: true
    },
    CAISO: DEFAULT_CONFIG,
    SPP: {
        ...DEFAULT_CONFIG,
        scenariosTable: {
            columnsToRemove: ["inputData", "costAssumptions"]
        },
        allocatedUpgradesTable: { columnsToRemove: ["networkUpgradeId"] },
        sizeLabel: {
            energyLabel: "ERIS",
            capacityLabel: "NRIS"
        },
        scenarioProjectsTable: {
            columnsToRemove: [
                "subregion",
                "erisThermal",
                "erisComplex",
                "nrisThermal",
                "nrisComplex",
                "erisSnu",
                "erisLpc"
            ]
        },
        constraintsTable: {
            columnsToRemove: ["preStudyFlow", "preStudyLoad", "facilityDetails"]
        },
        availableClusters: {
            DISIS_2022: {
                default_group: {
                    two: {
                        phaseStatus: PREVIEW_MODE,
                        configurableCostEstimatesForPreview: false
                    }
                }
            }
        }
    },
    ERCOT: DEFAULT_CONFIG,
    WECC: DEFAULT_CONFIG,
    TVA: DEFAULT_CONFIG,
    ISONE: DEFAULT_CONFIG,
    SOCO: DEFAULT_CONFIG,
    DUKE: DEFAULT_CONFIG
};
