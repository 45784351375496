import {
    GetAllProjectsQuery,
    GetInQueueSubscriptionsQuery,
    SubscribeToTrackedProjectsSubscription
} from "generated/graphql";
import { keyBy } from "lodash";
import { Loading, zipLoadingStates } from "types/loadingType";
import { convertToRegionType } from "types/regionType";
import {
    clusterToClusterId,
    InQueueCluster,
    StudyCycle,
    StudyGroup,
    StudyPhase
} from "./clusterType";

export const IN_QUEUE_DEFAULT_GROUP = "default_group";

export type ProjectSize = { erisMw: number; nrisMw: number };
export const EMPTY_PROJECT_SIZE: ProjectSize = { erisMw: 0, nrisMw: 0 };

export const PRE_ACTIVATION = "pre_activation";
export const ACTIVATED = "activated";
export const POST_DECISION_POINT = "post_decision_point";

export const IN_QUEUE_SUBSCRIPTION_STATUSES = [
    PRE_ACTIVATION,
    ACTIVATED,
    POST_DECISION_POINT
] as const;
export type InQueueSubscriptionStatus =
    (typeof IN_QUEUE_SUBSCRIPTION_STATUSES)[number];

export interface Project {
    projectId: string;
    cluster: InQueueCluster;
    fuelType: string;
    size: ProjectSize;
    isEditable: boolean;
}

export interface InQueueSubscription {
    subscriptionId: string;
    cluster: InQueueCluster;
    scenarioLimit: number;
    status: InQueueSubscriptionStatus;
}

export interface TrackedProject extends Project {
    subscriptionId: string;
}

export const convertHasuraProjectsToProjects = (
    hasuraData: GetAllProjectsQuery
): Project[] => {
    return hasuraData.all_projects.map((hasuraProject) => ({
        projectId: hasuraProject.project_id,
        cluster: {
            region: convertToRegionType(hasuraProject.region),
            studyGroup: hasuraProject.study_group as StudyGroup,
            studyCycle: hasuraProject.study_cycle as StudyCycle,
            studyPhase: hasuraProject.study_phase as StudyPhase
        },
        fuelType: hasuraProject.fuel_type,
        size: {
            nrisMw: hasuraProject.nris_capacity_mw,
            erisMw: hasuraProject.eris_capacity_mw
        },
        isEditable: !!hasuraProject.is_editable
    }));
};

type SubscriptionQueryResult =
    GetInQueueSubscriptionsQuery["in_queue_subscriptions"][0];

const hasuraSubscriptionToSubscription = (
    hasuraSubscription: SubscriptionQueryResult
): InQueueSubscription => {
    return {
        subscriptionId: hasuraSubscription.subscription_id,
        cluster: {
            region: convertToRegionType(hasuraSubscription.region),
            studyCycle: hasuraSubscription.study_cycle as StudyCycle,
            studyGroup: hasuraSubscription.study_group as StudyGroup,
            studyPhase: hasuraSubscription.phase as StudyPhase
        },
        scenarioLimit: hasuraSubscription.scenario_limit,
        status: convertToInQueueSubscriptionStatus(hasuraSubscription.status)
    };
};

export const convertHasuraSubscriptionsToSubscriptions = (
    hasuraData: GetInQueueSubscriptionsQuery
): InQueueSubscription[] => {
    return hasuraData.in_queue_subscriptions
        .map(hasuraSubscriptionToSubscription)
        .filter((sub: InQueueSubscription) => {
            try {
                return clusterToClusterId(sub.cluster);
            } catch (error) {
                console.warn(
                    `The following subscription is not supported: ${sub.cluster.region} ${sub.cluster.studyCycle} ${sub.cluster.studyGroup} Phase ${sub.cluster.studyPhase}`
                );
            }
        });
};

export const getTrackedProjects = (
    hasuraData: Loading<SubscribeToTrackedProjectsSubscription>,
    loadingAllProjects: Loading<Project[]>,
    subscriptions: Loading<InQueueSubscription[]>
): Loading<TrackedProject[]> => {
    if (subscriptions === "loading") {
        return "loading";
    }

    return zipLoadingStates(
        hasuraData,
        loadingAllProjects,
        (hasuraData, allProjects) => {
            const subscriptionsById = keyBy(
                subscriptions,
                (s: InQueueSubscription) => s.subscriptionId
            );
            const projectsByIdAndPhase = keyBy(
                allProjects,
                (p) => `${p.projectId}_${p.cluster.studyPhase}`
            );

            const trackedProjects: TrackedProject[] = [];
            hasuraData.tracked_projects.forEach((trackedProject) => {
                // First we need to find the matching subscription by subscription ID
                const subscription =
                    subscriptionsById[trackedProject.subscription_id];

                // Then we need to find the matching project by project ID + phase. It's
                // important to also consider the phase because we have data for projects
                // across multiple phases
                const project =
                    projectsByIdAndPhase[
                        `${trackedProject.project_id}_${subscription.cluster.studyPhase}`
                    ];
                if (project) {
                    trackedProjects.push({
                        ...project,
                        subscriptionId: trackedProject.subscription_id
                    });
                }
            });
            return trackedProjects;
        }
    );
};

export const getProjectIdForSubscription = (subscription: {
    project_ids: unknown;
}): string => {
    if (
        !subscription.project_ids ||
        !Array.isArray(subscription.project_ids) ||
        subscription.project_ids.length !== 1
    ) {
        throw new Error("Got unexpected projectIds result for subscription.");
    }

    return subscription.project_ids[0];
};

const isKnownInQueueSubscriptionStatus = (
    type: string
): type is InQueueSubscriptionStatus => {
    return IN_QUEUE_SUBSCRIPTION_STATUSES.includes(
        type as InQueueSubscriptionStatus
    );
};

const convertToInQueueSubscriptionStatus = (
    string: string
): InQueueSubscriptionStatus => {
    if (isKnownInQueueSubscriptionStatus(string)) {
        return string;
    }
    throw new Error("Unexpected status type");
};
