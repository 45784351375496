import {
    GeneratorType,
    LOAD_TYPE,
    SOLAR_TYPE,
    STORAGE_TYPE,
    WIND_TYPE
} from "./generatorType";
import {
    CAISO,
    DUKE,
    ERCOT,
    ISONE,
    MISO,
    NYISO,
    PJM,
    RegionType,
    SOCO,
    SPP,
    TVA,
    WECC
} from "./regionType";

export const MISO_SOLAR_DIRECTORY = "miso-solar";
export const MISO_WIND_DIRECTORY = "miso-wind";
export const MISO_STORAGE_DIRECTORY = "miso-storage";
export const MISO_LOAD_DIRECTORY = "miso-load";
export const PJM_DIRECTORY = "pjm";
export const PJM_LOAD_DIRECTORY = "pjm-load";
export const CAISO_DIRECTORY = "caiso";
export const SPP_SOLAR_DIRECTORY = "spp-solar";
export const SPP_WIND_DIRECTORY = "spp-wind";
export const ERCOT_SOLAR_DIRECTORY = "ercot-solar";
export const ERCOT_WIND_DIRECTORY = "ercot-wind";
export const ERCOT_STORAGE_DIRECTORY = "ercot-storage";
export const ERCOT_LOAD_DIRECTORY = "ercot-load";
export const WECC_SOLAR_DIRECTORY = "wecc-solar";
export const WECC_STORAGE_DIRECTORY = "wecc-storage";
export const NYISO_SOLAR_DIRECTORY = "nyiso-solar";
export const NYISO_STORAGE_DIRECTORY = "nyiso-storage";
export const TVA_SOLAR_DIRECTORY = "tva-solar";
export const ISONE_SOLAR_DIRECTORY = "isone-solar";
export const ISONE_STORAGE_DIRECTORY = "isone-storage";
export const SOCO_SOLAR_DIRECTORY = "soco-solar";
export const DUKE_SOLAR_DIRECTORY = "duke-solar";
export const DUKE_STORAGE_DIRECTORY = "duke-storage";

export const URL_DIRECTORY_TYPES = [
    MISO_SOLAR_DIRECTORY,
    MISO_WIND_DIRECTORY,
    MISO_STORAGE_DIRECTORY,
    MISO_LOAD_DIRECTORY,
    PJM_DIRECTORY,
    PJM_LOAD_DIRECTORY,
    CAISO_DIRECTORY,
    SPP_SOLAR_DIRECTORY,
    SPP_WIND_DIRECTORY,
    ERCOT_SOLAR_DIRECTORY,
    ERCOT_WIND_DIRECTORY,
    ERCOT_STORAGE_DIRECTORY,
    ERCOT_LOAD_DIRECTORY,
    WECC_SOLAR_DIRECTORY,
    WECC_STORAGE_DIRECTORY,
    NYISO_SOLAR_DIRECTORY,
    NYISO_STORAGE_DIRECTORY,
    TVA_SOLAR_DIRECTORY,
    ISONE_SOLAR_DIRECTORY,
    ISONE_STORAGE_DIRECTORY,
    SOCO_SOLAR_DIRECTORY,
    DUKE_SOLAR_DIRECTORY,
    DUKE_STORAGE_DIRECTORY
] as const;
export type UrlDirectoryType = (typeof URL_DIRECTORY_TYPES)[number];

export const isKnownUrlDirectoryType = (
    type: string
): type is UrlDirectoryType =>
    URL_DIRECTORY_TYPES.includes(type as UrlDirectoryType);

export const convertToUrlDirectoryType = (string: string): UrlDirectoryType => {
    if (isKnownUrlDirectoryType(string)) {
        return string;
    }

    throw new Error("Unexpected urlDirectory type");
};

export const REGION_TO_FUEL_TYPE_TO_URL_DICT: {
    [key in RegionType]: {
        [key in GeneratorType]: null | UrlDirectoryType;
    };
} = {
    NYISO: {
        solar: NYISO_SOLAR_DIRECTORY,
        storage: NYISO_STORAGE_DIRECTORY,
        wind: null,
        load: null
    },
    PJM: {
        solar: PJM_DIRECTORY,
        storage: null,
        wind: null,
        load: PJM_LOAD_DIRECTORY
    },
    MISO: {
        solar: MISO_SOLAR_DIRECTORY,
        storage: MISO_STORAGE_DIRECTORY,
        wind: MISO_WIND_DIRECTORY,
        load: MISO_LOAD_DIRECTORY
    },
    CAISO: {
        solar: null,
        storage: null,
        wind: null,
        load: null
    },
    SPP: {
        solar: SPP_SOLAR_DIRECTORY,
        storage: null,
        wind: SPP_WIND_DIRECTORY,
        load: null
    },
    ERCOT: {
        solar: ERCOT_SOLAR_DIRECTORY,
        storage: ERCOT_STORAGE_DIRECTORY,
        wind: ERCOT_WIND_DIRECTORY,
        load: ERCOT_LOAD_DIRECTORY
    },
    WECC: {
        solar: WECC_SOLAR_DIRECTORY,
        storage: WECC_STORAGE_DIRECTORY,
        wind: null,
        load: null
    },
    TVA: {
        solar: TVA_SOLAR_DIRECTORY,
        storage: null,
        wind: null,
        load: null
    },
    ISONE: {
        solar: ISONE_SOLAR_DIRECTORY,
        storage: ISONE_STORAGE_DIRECTORY,
        wind: null,
        load: null
    },
    SOCO: {
        solar: SOCO_SOLAR_DIRECTORY,
        storage: null,
        wind: null,
        load: null
    },
    DUKE: {
        solar: DUKE_SOLAR_DIRECTORY,
        storage: DUKE_STORAGE_DIRECTORY,
        wind: null,
        load: null
    }
};

// TODO: Rewrite this to use REGION_TO_FUEL_TYPE_TO_URL_DICT above, which is hard to do while maintaining type-safety
export const getGeneratorTypeFromUrlDirectory = (
    urlDirectory: UrlDirectoryType
): GeneratorType => {
    const urlDirectoryToGeneratorType: {
        [key in UrlDirectoryType]: GeneratorType;
    } = {
        [MISO_SOLAR_DIRECTORY]: SOLAR_TYPE,
        [MISO_WIND_DIRECTORY]: WIND_TYPE,
        [MISO_STORAGE_DIRECTORY]: STORAGE_TYPE,
        [MISO_LOAD_DIRECTORY]: LOAD_TYPE,
        [PJM_DIRECTORY]: SOLAR_TYPE,
        [PJM_LOAD_DIRECTORY]: LOAD_TYPE,
        [CAISO_DIRECTORY]: SOLAR_TYPE,
        [SPP_SOLAR_DIRECTORY]: SOLAR_TYPE,
        [SPP_WIND_DIRECTORY]: WIND_TYPE,
        [ERCOT_SOLAR_DIRECTORY]: SOLAR_TYPE,
        [ERCOT_WIND_DIRECTORY]: WIND_TYPE,
        [ERCOT_STORAGE_DIRECTORY]: STORAGE_TYPE,
        [ERCOT_LOAD_DIRECTORY]: LOAD_TYPE,
        [WECC_SOLAR_DIRECTORY]: SOLAR_TYPE,
        [WECC_STORAGE_DIRECTORY]: STORAGE_TYPE,
        [NYISO_SOLAR_DIRECTORY]: SOLAR_TYPE,
        [NYISO_STORAGE_DIRECTORY]: STORAGE_TYPE,
        [TVA_SOLAR_DIRECTORY]: SOLAR_TYPE,
        [ISONE_SOLAR_DIRECTORY]: SOLAR_TYPE,
        [ISONE_STORAGE_DIRECTORY]: STORAGE_TYPE,
        [SOCO_SOLAR_DIRECTORY]: SOLAR_TYPE,
        [DUKE_SOLAR_DIRECTORY]: SOLAR_TYPE,
        [DUKE_STORAGE_DIRECTORY]: STORAGE_TYPE
    };

    return urlDirectoryToGeneratorType[urlDirectory];
};

// TODO: Rewrite this to use REGION_TO_FUEL_TYPE_TO_URL_DICT above, which is hard to do while maintaining type-safety
export const getRegionFromUrlDirectory = (
    urlDirectory: UrlDirectoryType
): RegionType => {
    const urlDirectoryToRegionType: {
        [key in UrlDirectoryType]: RegionType;
    } = {
        [MISO_SOLAR_DIRECTORY]: MISO,
        [MISO_WIND_DIRECTORY]: MISO,
        [MISO_STORAGE_DIRECTORY]: MISO,
        [MISO_LOAD_DIRECTORY]: MISO,
        [PJM_DIRECTORY]: PJM,
        [PJM_LOAD_DIRECTORY]: PJM,
        [CAISO_DIRECTORY]: CAISO,
        [SPP_SOLAR_DIRECTORY]: SPP,
        [SPP_WIND_DIRECTORY]: SPP,
        [ERCOT_SOLAR_DIRECTORY]: ERCOT,
        [ERCOT_WIND_DIRECTORY]: ERCOT,
        [ERCOT_STORAGE_DIRECTORY]: ERCOT,
        [ERCOT_LOAD_DIRECTORY]: ERCOT,
        [WECC_SOLAR_DIRECTORY]: WECC,
        [WECC_STORAGE_DIRECTORY]: WECC,
        [NYISO_SOLAR_DIRECTORY]: NYISO,
        [NYISO_STORAGE_DIRECTORY]: NYISO,
        [TVA_SOLAR_DIRECTORY]: TVA,
        [ISONE_SOLAR_DIRECTORY]: ISONE,
        [ISONE_STORAGE_DIRECTORY]: ISONE,
        [SOCO_SOLAR_DIRECTORY]: SOCO,
        [DUKE_SOLAR_DIRECTORY]: DUKE,
        [DUKE_STORAGE_DIRECTORY]: DUKE
    };

    return urlDirectoryToRegionType[urlDirectory];
};
