import {
    Button,
    ButtonGroup,
    Card,
    CardList,
    HTMLSelect,
    Intent,
    Menu,
    MenuItem,
    NumericInput,
    Popover,
    Position,
    Section,
    SectionCard,
    Toaster
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Select } from "@blueprintjs/select";
import saveAs from "file-saver";
import {
    useAllEditableProjectsInCluster,
    useProject
} from "in_queue/contexts/ProjectDataContext";
import { Project } from "in_queue/types/projectType";
import {
    MisoPercentageReduction,
    ProjectSizeAdjustmentType
} from "in_queue/types/scenarioType";
import { isEqual } from "lodash";
import Papa, { ParseResult } from "papaparse";
import { ChangeEvent, useState } from "react";
import { useParams } from "react-router-dom";
import { ProjectSizePreview } from "../Scenario/ProjectSizePreview";
import "./SensitivitiesInput.scss";

type SensitivitiesInputProps = {
    projectSizeAssumptions: Record<string, MisoPercentageReduction>;
    setProjectSizeAssumptions: (
        newProjectSizeAssumptions: Record<string, MisoPercentageReduction>
    ) => void;
};

export const SensitivitiesInput: React.FC<SensitivitiesInputProps> = ({
    projectSizeAssumptions,
    setProjectSizeAssumptions
}: SensitivitiesInputProps) => {
    const { projectId } = useParams();
    const editableProjectsInCluster = useAllEditableProjectsInCluster();

    if (!projectId) {
        return <></>;
    }

    const onAddSensitivity = (projectId: string) => {
        const newProjectSizeAssumptions = {
            ...projectSizeAssumptions,

            // By default, add new sensitivities as "excluded"
            [projectId]: {
                type: ProjectSizeAdjustmentType.PERCENTAGE_REDUCTION,
                eris: 100,
                nris: 100
            }
        };

        setProjectSizeAssumptions(newProjectSizeAssumptions);
    };

    const onChangeSensitivity = ({
        projectId,
        newProjectSizeAssumption
    }: {
        projectId: string;
        newProjectSizeAssumption: MisoPercentageReduction;
    }) => {
        const newProjectSizeAssumptions = {
            ...projectSizeAssumptions,
            [projectId]: newProjectSizeAssumption
        };

        setProjectSizeAssumptions(newProjectSizeAssumptions);
    };

    const onDeleteSensitivity = (projectId: string) => {
        const newProjectSizeAssumptions = { ...projectSizeAssumptions };
        if (projectId in newProjectSizeAssumptions) {
            delete newProjectSizeAssumptions[projectId];
        }

        setProjectSizeAssumptions(newProjectSizeAssumptions);
    };

    const addSensitivityItems =
        editableProjectsInCluster === "loading"
            ? []
            : editableProjectsInCluster.filter(
                  (project: Project) =>
                      !Object.keys(projectSizeAssumptions).includes(
                          project.projectId
                      )
              );
    const validProjectIds = new Set(
        editableProjectsInCluster === "loading"
            ? []
            : editableProjectsInCluster
                  .filter((p) => p.projectId !== projectId)
                  .map((p) => p.projectId)
    );

    const addSensitivityButton = (
        <Select
            disabled={editableProjectsInCluster === "loading"}
            items={addSensitivityItems}
            itemPredicate={(query, project) =>
                project.projectId.toLowerCase().includes(query.toLowerCase())
            }
            itemRenderer={(project: Project, { handleClick }) => (
                <MenuItem
                    text={project.projectId}
                    key={project.projectId}
                    onClick={handleClick}
                />
            )}
            onItemSelect={(project: Project) =>
                onAddSensitivity(project.projectId)
            }
            resetOnClose
            popoverProps={{
                minimal: true
            }}
        >
            <Button icon={IconNames.SEARCH} text="Add sensitivity" outlined />
        </Select>
    );

    const moreOptionsButton = (
        <Popover
            content={
                <UploadSensitivitiesMenu
                    currentProjectId={projectId}
                    currentProjectSizeAssumptions={projectSizeAssumptions}
                    validProjectIds={validProjectIds}
                    setProjectSizeAssumptions={setProjectSizeAssumptions}
                />
            }
            position={Position.BOTTOM_RIGHT}
        >
            <Button icon={IconNames.CARET_DOWN} outlined />
        </Popover>
    );

    const buttons = (
        <ButtonGroup>
            {addSensitivityButton}
            {moreOptionsButton}
        </ButtonGroup>
    );

    const sensitivitiesToDisplay = Object.keys(projectSizeAssumptions).filter(
        (pId: string) => pId != projectId
    );
    const sensitivityTitleSuffix =
        sensitivitiesToDisplay.length > 0
            ? ` (${sensitivitiesToDisplay.length.toLocaleString()})`
            : "";

    return (
        <Section
            title={`Sensitivities${sensitivityTitleSuffix}`}
            rightElement={buttons}
            compact={true}
            className="sensitivities-section"
        >
            <SectionCard
                padded={false}
                className="sensitivities-list-container"
            >
                <CardList bordered={false} compact={true}>
                    {sensitivitiesToDisplay.length > 0 ? (
                        sensitivitiesToDisplay.map((projectId: string) => (
                            <Card
                                key={projectId}
                                className="sensitivities-list"
                            >
                                <ProjectSensitivity
                                    projectSizeAssumption={
                                        projectSizeAssumptions[projectId]
                                    }
                                    projectId={projectId}
                                    onChangeSensitivity={onChangeSensitivity}
                                />
                                <Button
                                    minimal={true}
                                    small={true}
                                    icon={IconNames.TRASH}
                                    onClick={() =>
                                        onDeleteSensitivity(projectId)
                                    }
                                />
                            </Card>
                        ))
                    ) : (
                        <Card className="no-sensitivities">
                            No sensitivities added yet
                        </Card>
                    )}
                </CardList>
            </SectionCard>
        </Section>
    );
};

const projectIdFieldName = "project_id";
const erisFieldName = "eris_percentage_reduction";
const nrisFieldName = "nris_percentage_reduction";

interface ParsedCsvItem {
    [projectIdFieldName]: string;
    [erisFieldName]: string;
    [nrisFieldName]: string;
}
interface UploadSensitivitiesProps {
    currentProjectId: string;
    currentProjectSizeAssumptions: Record<string, MisoPercentageReduction>;
    validProjectIds: Set<string>;
    setProjectSizeAssumptions: (
        newProjectSizeAssumptions: Record<string, MisoPercentageReduction>
    ) => void;
}
export const UploadSensitivitiesMenu: React.FC<UploadSensitivitiesProps> = ({
    currentProjectId,
    currentProjectSizeAssumptions,
    validProjectIds,
    setProjectSizeAssumptions
}) => {
    /**
     * Handles the results after the uploaded CSV is parsed using the papaparse library.
     */
    const parseCsvResults = (results: ParseResult<ParsedCsvItem>) => {
        // Validate CSV header matches what we expect
        if (
            !isEqual(results.meta.fields, [
                projectIdFieldName,
                erisFieldName,
                nrisFieldName
            ])
        ) {
            SensitivitiesInputToaster.show({
                intent: Intent.DANGER,
                message: `Uploaded CSV header is invalid. The CSV must have three columns with fields named "${projectIdFieldName}", "${erisFieldName}", "${nrisFieldName}"`
            });
            return;
        }

        const newAssumptions: Record<string, MisoPercentageReduction> = {};
        const warnings: string[] = [];
        results.data.forEach((item) => {
            // Validate projectId is in this cluster
            const projectId = item[projectIdFieldName];
            if (!validProjectIds.has(projectId)) {
                warnings.push(projectId);
                return;
            }
            const eris = parseFloat(item[erisFieldName]);
            const nris = parseFloat(item[nrisFieldName]);

            // Validate ERIS and NRIS inputs are numbers
            if (isNaN(eris) || isNaN(nris)) {
                warnings.push(projectId);
                return;
            }

            // Validate ERIS and NRIS inputs are between 0 and 100
            if (eris < 0 || eris > 100 || nris < 0 || nris > 100) {
                warnings.push(projectId);
                return;
            }

            newAssumptions[item.project_id] = {
                type: ProjectSizeAdjustmentType.PERCENTAGE_REDUCTION,
                eris,
                nris
            };
        });

        if (warnings.length > 0) {
            SensitivitiesInputToaster.show({
                intent: Intent.WARNING,
                message: (
                    <>
                        Unable to process sensitivities for the following{" "}
                        <b>{warnings.length}</b> project(s). Please check the
                        project ID is valid, and that percentage reductions are
                        a number between 0 and 100. Projects:{" "}
                        <b>{warnings.join(", ")}</b>.
                    </>
                )
            });
        }

        setProjectSizeAssumptions({
            ...newAssumptions,
            // Preserve current project's size assumptions
            [currentProjectId]: currentProjectSizeAssumptions[currentProjectId]
        });
    };

    /**
     * Invoked when a user selects a CSV file to upload. Parses the CSV file
     * and passes the results to `parseCsvResults` above.
     */
    const uploadChangeHandler = (e: Event) => {
        if (e.target == null) {
            return;
        }
        const target = e.target as HTMLInputElement;
        if (target.files == null) {
            return;
        }
        const file = target.files[0];
        Papa.parse<ParsedCsvItem>(file, {
            header: true,
            skipEmptyLines: true,
            complete: parseCsvResults
        });
    };

    /**
     * Creates a placeholder file HTML input element and opens it for the user.
     */
    const handleUploadClick = () => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = ".csv, text/csv";
        input.onchange = uploadChangeHandler;
        input.click();
    };

    const downloadCsvTemplate = () => {
        const csvHeader = `${projectIdFieldName},${erisFieldName},${nrisFieldName}`;
        const blob = new Blob([csvHeader], { type: "text/csv" });
        saveAs(blob, "sensitivities-template.csv");
    };

    return (
        <Menu>
            <MenuItem
                icon={IconNames.IMPORT}
                text="Upload sensitivities"
                label="(.csv)"
                onClick={handleUploadClick}
            />
            <MenuItem
                icon={IconNames.TH}
                text="Download template"
                label="(.csv)"
                onClick={downloadCsvTemplate}
            />
        </Menu>
    );
};

enum SensitivityType {
    EXCLUSION = "exclusion",
    DOWNSIZE = "downsize"
}

const ProjectSensitivity: React.FC<{
    projectId: string;
    projectSizeAssumption: MisoPercentageReduction;
    onChangeSensitivity: (params: {
        projectId: string;
        newProjectSizeAssumption: MisoPercentageReduction;
    }) => void;
}> = ({ projectId, projectSizeAssumption, onChangeSensitivity }) => {
    const [sensitivityType, setSensitivityType] = useState<SensitivityType>(
        projectSizeAssumption.eris === 100 && projectSizeAssumption.nris === 100
            ? SensitivityType.EXCLUSION
            : SensitivityType.DOWNSIZE
    );

    const project = useProject({ projectId });
    if (!project || project === "loading") {
        return <></>;
    }

    const onChangeSensitivityType = (newSensitivityType: SensitivityType) => {
        setSensitivityType(newSensitivityType);

        const newProjectSizeAssumption = {
            ...projectSizeAssumption,
            eris: 100,
            nris: 100
        };
        if (newSensitivityType === SensitivityType.DOWNSIZE) {
            // Set the default size reduction to 10%
            newProjectSizeAssumption["eris"] = 10;
            newProjectSizeAssumption["nris"] = 10;
        }
        onChangeSensitivity({ projectId, newProjectSizeAssumption });
    };

    const onChangeSizeReduction = (newSizeReduction: number) => {
        const newProjectSizeAssumption = {
            ...projectSizeAssumption,
            eris: newSizeReduction,
            nris: newSizeReduction
        };
        onChangeSensitivity({ projectId, newProjectSizeAssumption });
    };

    return (
        <div className="project-sensitivity-metadata">
            <span>
                <strong>{projectId}</strong> is
            </span>
            <HTMLSelect
                className="sensitivity-type-select"
                value={sensitivityType}
                onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                    onChangeSensitivityType(
                        event.target.value as SensitivityType
                    )
                }
                options={[
                    { label: "excluded", value: SensitivityType.EXCLUSION },
                    { label: "downsized", value: SensitivityType.DOWNSIZE }
                ]}
            />

            {sensitivityType === SensitivityType.DOWNSIZE && (
                <>
                    <span>by</span>
                    <NumericInput
                        className="sensitivity-size-input"
                        stepSize={1}
                        min={0}
                        max={100}
                        rightElement={
                            <span className="sensitivity-input-icon">%</span>
                        }
                        value={projectSizeAssumption.eris}
                        allowNumericCharactersOnly
                        onValueChange={(newValue) => {
                            if (newValue >= 0 && newValue <= 100) {
                                onChangeSizeReduction(newValue);
                            }
                        }}
                        small
                        required
                    />
                    <span className="sensitivity-project-size-preview">
                        <ProjectSizePreview
                            projectSize={project?.size}
                            percentageReduction={projectSizeAssumption}
                        />
                    </span>
                </>
            )}
        </div>
    );
};

const SensitivitiesInputToaster = Toaster.create({
    position: Position.TOP
});
