import { useUserDataContext } from "contexts/UserDataContext/UserDataContext";
import { useStageConfig } from "in_queue/contexts/ClusterContext";
import { ScenarioStage } from "in_queue/types/scenarioType";
import { ADMIN_TYPE } from "types/roleType";
import { useIsAtScenarioLimit } from "./useIsAtScenarioLimit";
import { useIsSubscriptionStatusActive } from "./useIsSubscriptionStatusActive";

export const useScenarioCreationDisabled = (stage: ScenarioStage): boolean => {
    const { role } = useUserDataContext();
    const isAtScenarioLimit = useIsAtScenarioLimit();
    const stageConfig = useStageConfig();
    const isSubscriptionActive = useIsSubscriptionStatusActive();
    return (
        role !== ADMIN_TYPE ||
        isAtScenarioLimit ||
        !stageConfig.phaseStatus[stage].createButtonEnabled ||
        !isSubscriptionActive
    );
};
