import {
    Classes,
    DialogBody,
    DialogStep,
    FormGroup,
    H4,
    InputGroup,
    Intent,
    MultiSlider,
    MultistepDialog,
    Position,
    RadioCard,
    RadioGroup,
    Switch,
    Toaster
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import classNames from "classnames";
import { useUserDataContext } from "contexts/UserDataContext/UserDataContext";
import { IN_QUEUE_PATH } from "infrastructure/RouterApp";
import {
    segmentTrackCreateScenario,
    segmentTrackEditScenario
} from "in_queue/analytics";
import {
    useCluster,
    useRegionConfig,
    useStageConfig
} from "in_queue/contexts/ClusterContext";
import { useTrackedProject } from "in_queue/contexts/ProjectDataContext";
import { clusterToClusterId } from "in_queue/types/clusterType";
import {
    CostAssumption,
    CostAssumptionType,
    MisoPercentageReduction,
    ProjectSizeAdjustmentType,
    ScenarioMetadata,
    ScenarioStage
} from "in_queue/types/scenarioType";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { VisibilityInput } from "../VisibilityInput";
import { ProjectSizeInput } from "./ProjectSizeInput";
import {
    getProjectSize,
    getScenarioDialogConfigFromExistingScenario,
    useCreateScenario,
    useUpdateScenario
} from "./ScenarioDialog.helpers";
import css from "./ScenarioDialog.module.scss";
import { ScenarioPreviewCallout } from "./ScenarioPreviewCallout";
import { SensitivitiesInput } from "./SensitivitiesInput";

type ScenarioDialogProps = {
    readonly isOpen: boolean;
    readonly onClose: () => void;
    readonly action?: "create" | "update";
    readonly scenario?: ScenarioMetadata;
    readonly scenarioStage?: ScenarioStage;
};

const PROJECT_SIZE_PANEL_TITLE = "Adjust project size";
const SENSITIVITIES_PANEL_TITLE = "Add sensitivities";
const COST_ASSUMPTIONS_PANEL_TITLE = "Cost assumptions";
const SUBMIT_SCENARIO_PANEL_TITLE = "Submit scenario";
const UPDATE_SCENARIO_PANEL_TITLE = "Edit scenario";

export const ScenarioDialog: React.FC<ScenarioDialogProps> = ({
    isOpen,
    onClose,
    action = "create",
    scenario,
    scenarioStage = "DECISION_POINT"
}: ScenarioDialogProps) => {
    const createScenario = useCreateScenario();
    const updateScenario = useUpdateScenario();
    const { sizeLabel } = useRegionConfig();
    const stageConfig = useStageConfig();

    const navigate = useNavigate();

    const userData = useUserDataContext();
    const { projectId } = useParams();
    const cluster = useCluster();
    const project = useTrackedProject({
        projectId,
        phase: cluster.studyPhase
    });

    const [runOnSubmit, setRunOnSubmit] = useState(false);

    const scenarioDialogConfig = getScenarioDialogConfigFromExistingScenario({
        projectId,
        scenario,
        action,
        scenarioStage
    });

    const [title, setTitle] = useState(scenarioDialogConfig.title);
    const [projectSizeAssumptions, setProjectSizeAssumptions] = useState<
        Record<string, MisoPercentageReduction>
    >(scenarioDialogConfig.projectSizeAssumptions);
    const [parentProjectId, setParentProjectId] = useState<string | undefined>(
        projectId
    );
    const [costAssumptions, setCostAssumptions] = useState<CostAssumption>(
        scenarioDialogConfig.costAssumptions
    );

    const [stage, setStage] = useState<ScenarioStage>(
        scenarioDialogConfig.scenarioStage
    );

    useEffect(() => {
        // This is mainly needed because ScenarioActions renders a single ScenarioDialog but it's used
        // for both duplicating and editing scenarios; thus we need to be able to update the state
        // whenever any of the props changes.
        const scenarioDialogConfig =
            getScenarioDialogConfigFromExistingScenario({
                projectId,
                scenario,
                action,
                scenarioStage
            });

        setTitle(scenarioDialogConfig.title);
        setProjectSizeAssumptions(scenarioDialogConfig.projectSizeAssumptions);
        setStage(scenarioDialogConfig.scenarioStage);
        setCostAssumptions(scenarioDialogConfig.costAssumptions);
    }, [action, scenario, scenarioStage]);

    const [isLoading, setIsLoading] = useState(false);

    if (!projectId || !project || project === "loading") {
        return <></>;
    }

    const previewProjectSize = getProjectSize(
        project,
        projectSizeAssumptions[projectId]
    );

    const onChangeSizeReduction = ({
        type,
        newSize
    }: {
        type: "eris" | "nris" | "both";
        newSize: number;
    }) => {
        const projectSize = {
            type: ProjectSizeAdjustmentType.PERCENTAGE_REDUCTION,
            eris: projectSizeAssumptions[projectId]?.eris ?? 0,
            nris: projectSizeAssumptions[projectId]?.nris ?? 0
        };
        if (type === "both") {
            projectSize["eris"] = newSize;
            projectSize["nris"] = newSize;
        } else {
            projectSize[type] = newSize;
        }

        const newProjectSizeAssumptions = {
            ...projectSizeAssumptions
        };
        if (projectSize.eris > 0 || projectSize.nris > 0) {
            newProjectSizeAssumptions[projectId] = projectSize;
        } else {
            delete newProjectSizeAssumptions[projectId];
        }
        setProjectSizeAssumptions(newProjectSizeAssumptions);
    };

    const projectSizePanel = (
        <DialogBody className={css.body}>
            <div>
                <H4>{PROJECT_SIZE_PANEL_TITLE}</H4>
                <ProjectSizeInput
                    erisSizeReduction={
                        projectSizeAssumptions[projectId]?.eris || 0
                    }
                    nrisSizeReduction={
                        projectSizeAssumptions[projectId]?.nris || 0
                    }
                    onChangeSizeReduction={onChangeSizeReduction}
                />
            </div>
            <p>
                {projectId} size: {sizeLabel.energyLabel}{" "}
                <b>{previewProjectSize?.erisMw}MW</b>, {sizeLabel.capacityLabel}{" "}
                <b>{previewProjectSize?.nrisMw}MW</b>.
            </p>
        </DialogBody>
    );

    const sensitivitiesPanel = (
        <DialogBody className={css.body}>
            <div>
                <H4>{SENSITIVITIES_PANEL_TITLE}</H4>
                <SensitivitiesInput
                    projectSizeAssumptions={projectSizeAssumptions}
                    setProjectSizeAssumptions={setProjectSizeAssumptions}
                />
            </div>
        </DialogBody>
    );

    const costAssumptionsPanel = (
        <DialogBody className={css.body}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px"
                }}
            >
                <H4>{COST_ASSUMPTIONS_PANEL_TITLE}</H4>
                <RadioGroup
                    className={css.cards}
                    selectedValue={costAssumptions.type}
                    onChange={(element) => {
                        const newValue = element.currentTarget.value;
                        if (newValue === CostAssumptionType.CONSERVATIVE) {
                            setCostAssumptions({ type: newValue });
                        } else if (newValue === CostAssumptionType.CUSTOM) {
                            setCostAssumptions({
                                type: newValue,
                                reconductorLoadingLimit: 110.0,
                                rebuildLoadingLimit: 150.0
                            });
                        }
                    }}
                >
                    <RadioCard
                        className={css.card}
                        compact
                        alignIndicator="left"
                        value={CostAssumptionType.CONSERVATIVE}
                    >
                        Conservative
                        <span
                            className={classNames(
                                Classes.TEXT_MUTED,
                                Classes.TEXT_SMALL
                            )}
                        >
                            Assume a new line build for every constraint.
                        </span>
                    </RadioCard>
                    <RadioCard
                        className={css.card}
                        compact
                        alignIndicator="left"
                        value={CostAssumptionType.CUSTOM}
                    >
                        Custom
                        <span
                            className={classNames(
                                Classes.TEXT_MUTED,
                                Classes.TEXT_SMALL
                            )}
                        >
                            Define custom cost assumptions based on percentage
                            loading.
                        </span>
                    </RadioCard>
                </RadioGroup>
                {costAssumptions.type === CostAssumptionType.CUSTOM && (
                    <div>
                        <FormGroup
                            label="Thresholds"
                            subLabel="Define the thresholds at which lines are reconductored or rebuilt."
                            style={{ margin: "0 5px" }}
                        >
                            <div style={{ margin: "0 3px" }}>
                                <MultiSlider
                                    min={100}
                                    max={200}
                                    stepSize={1}
                                    labelStepSize={50}
                                >
                                    <MultiSlider.Handle
                                        value={
                                            costAssumptions.reconductorLoadingLimit
                                        }
                                        onChange={(evt) => {
                                            setCostAssumptions({
                                                ...costAssumptions,
                                                reconductorLoadingLimit: evt
                                            });
                                        }}
                                        interactionKind="push"
                                        trackStyleBefore={{
                                            backgroundColor: "#3DCC91"
                                        }}
                                        trackStyleAfter={{
                                            backgroundColor: "#FFB366"
                                        }}
                                    />
                                    <MultiSlider.Handle
                                        value={
                                            costAssumptions.rebuildLoadingLimit
                                        }
                                        onChange={(evt) => {
                                            setCostAssumptions({
                                                ...costAssumptions,
                                                rebuildLoadingLimit: evt
                                            });
                                        }}
                                        interactionKind="push"
                                        trackStyleAfter={{
                                            backgroundColor: "#FF7373"
                                        }}
                                    />
                                </MultiSlider>
                            </div>
                        </FormGroup>
                        <div>
                            <p>
                                <b>Reconductor</b> assumed for constraints
                                between 100% and{" "}
                                {costAssumptions.reconductorLoadingLimit}%
                                loading.
                            </p>
                            <p>
                                <b>Rebuild</b> assumed for constraints between{" "}
                                {costAssumptions.reconductorLoadingLimit}% and{" "}
                                {costAssumptions.rebuildLoadingLimit}% loading.
                            </p>
                            <p>
                                <b>New single-circuit line build</b> assumed for
                                constraints above{" "}
                                {costAssumptions.rebuildLoadingLimit}% loading.
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </DialogBody>
    );

    const finalPanelTitle =
        action == "update" && scenario
            ? UPDATE_SCENARIO_PANEL_TITLE
            : SUBMIT_SCENARIO_PANEL_TITLE;

    const submitPanel = (
        <DialogBody className={css.body}>
            <div>
                <H4>{finalPanelTitle}</H4>
                <FormGroup label="Title" labelInfo="(required)">
                    <InputGroup
                        placeholder="Enter a title..."
                        name="title"
                        required
                        autoFocus
                        value={title}
                        onValueChange={setTitle}
                    />
                </FormGroup>
                <FormGroup
                    label="Visibility"
                    subLabel="Select where this scenario should be visible. You can change this later."
                >
                    <VisibilityInput
                        parentProjectId={parentProjectId}
                        setParentProjectId={setParentProjectId}
                    />
                </FormGroup>
                {action === "create" && (
                    <FormGroup
                        label="Run scenario"
                        subLabel="If enabled, this scenario will be run immediately when you submit."
                    >
                        <Switch
                            label="Run this scenario now"
                            checked={runOnSubmit}
                            onChange={() => setRunOnSubmit(!runOnSubmit)}
                        />
                    </FormGroup>
                )}
                <ScenarioPreviewCallout
                    projectSizeAssumptions={projectSizeAssumptions}
                />
            </div>
        </DialogBody>
    );

    const onSubmit = () => {
        if (!project) {
            return;
        }

        const handleCreateScenario = async () => {
            try {
                if (action == "update" && scenario) {
                    const newScenario = await updateScenario({
                        scenarioId: scenario.scenarioId,
                        auth0Id: userData.auth0Id,
                        title,
                        runOnSubmit,
                        projectSizeAssumptions,
                        parentProjectId,
                        costAssumptions
                    });
                    if (!newScenario) {
                        onClickClose();
                        return;
                    }

                    segmentTrackEditScenario({
                        scenarioId: scenario.scenarioId,
                        scenarioTitle: title,
                        numProjectSizeAssumptions: Object.keys(
                            projectSizeAssumptions
                        ).length
                    });

                    CreateScenarioToaster.show({
                        intent: Intent.SUCCESS,
                        message: `Successfully updated scenario "${newScenario.title}"`
                    });

                    setIsLoading(false);
                } else {
                    const newScenario = await createScenario({
                        project,
                        userData,
                        title,
                        runOnSubmit,
                        projectSizeAssumptions,
                        scenarioStage: stage,
                        parentProjectId,
                        costAssumptions
                    });
                    if (!newScenario) {
                        onClickClose();
                        return;
                    }

                    segmentTrackCreateScenario({
                        region: project.cluster.region,
                        studyGroup: project.cluster.studyGroup,
                        studyCycle: project.cluster.studyCycle,
                        studyPhase: project.cluster.studyPhase,
                        scenarioStage: scenarioStage,
                        scenarioTitle: title,
                        numProjectSizeAssumptions: Object.keys(
                            projectSizeAssumptions
                        ).length
                    });

                    const clusterId = clusterToClusterId(project.cluster);
                    CreateScenarioToaster.show({
                        intent: Intent.SUCCESS,
                        message: `Successfully created new scenario "${newScenario.title}"`,
                        action: {
                            text: "Open",
                            onClick: (
                                event: React.MouseEvent<HTMLElement, MouseEvent>
                            ) => {
                                event.preventDefault();
                                navigate(
                                    `/${IN_QUEUE_PATH}/${clusterId}/${projectId}/scenario/${newScenario.scenario_id}`
                                );
                            },
                            href: `/${IN_QUEUE_PATH}/${clusterId}/${projectId}/scenario/${newScenario.scenario_id}`
                        }
                    });

                    setIsLoading(false);
                }
            } catch (error) {
                CreateScenarioToaster.show({
                    intent: Intent.DANGER,
                    message: `Encountered an error while ${
                        action === "create" ? "creating new" : "updating"
                    } scenario. Please try again.`
                });

                setIsLoading(false);
                return;
            }

            onClickClose();
        };

        setIsLoading(true);
        handleCreateScenario();
    };

    const onClickClose = () => {
        // Before we close the scenario, we need to reset the state based on the props that were passed in
        const scenarioDialogConfig =
            getScenarioDialogConfigFromExistingScenario({
                projectId,
                scenario,
                action,
                scenarioStage
            });

        setTitle(scenarioDialogConfig.title);
        setProjectSizeAssumptions(scenarioDialogConfig.projectSizeAssumptions);
        setStage(scenarioDialogConfig.scenarioStage);
        onClose();
    };

    return (
        <MultistepDialog
            isOpen={isOpen}
            onClose={onClickClose}
            showCloseButtonInFooter
            title={finalPanelTitle}
            icon={IconNames.ADD}
            finalButtonProps={{
                onClick: onSubmit,
                disabled: !title,
                tooltipContent: !title ? "Please enter a title" : undefined,
                loading: isLoading
            }}
        >
            <DialogStep
                id="project-size"
                title={PROJECT_SIZE_PANEL_TITLE}
                panel={projectSizePanel}
            />
            <DialogStep
                id="sensitivities"
                panel={sensitivitiesPanel}
                title={SENSITIVITIES_PANEL_TITLE}
            />
            {stage === "PREVIEW" &&
                stageConfig.configurableCostEstimatesForPreview && (
                    <DialogStep
                        id="cost-assumptions"
                        panel={costAssumptionsPanel}
                        title={COST_ASSUMPTIONS_PANEL_TITLE}
                    />
                )}
            <DialogStep
                id="submit"
                panel={submitPanel}
                title={finalPanelTitle}
            />
        </MultistepDialog>
    );
};

const CreateScenarioToaster = Toaster.create({
    position: Position.TOP
});
