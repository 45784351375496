import { round } from "lodash";
import { QUERY_PARAMS_FOR_MAP_VIEW } from "./queryParams";
import { RegionType } from "./regionType";
import { ScreeningView } from "./screeningViewType";
import { STATE_TO_DEFAULT_COORDINATES } from "./stateType";

export type Coordinates = {
    readonly latitude: number;
    readonly longitude: number;
};

export type CoordinatesAndZoom = Coordinates & {
    readonly zoom: number;
};

const REGIONS_TO_DEFAULT_COORDINATES: {
    readonly [key in RegionType]: CoordinatesAndZoom;
} = {
    NYISO: { latitude: 42.915, longitude: -74.572, zoom: 8 },
    PJM: { latitude: 40.7585, longitude: -82.69877, zoom: 8 },
    MISO: { latitude: 40.859988891, longitude: -86.45, zoom: 8 },
    CAISO: { latitude: 35.42, longitude: -119.627, zoom: 8 },
    SPP: { latitude: 41.613, longitude: -98.931, zoom: 8 },
    ERCOT: { latitude: 31.302, longitude: -97.07, zoom: 8 },
    WECC: { latitude: 38.016, longitude: -107.047, zoom: 8 },
    TVA: { latitude: 35.625, longitude: -85.219, zoom: 8 },
    ISONE: { latitude: 42.272, longitude: -71.752, zoom: 8 },
    SOCO: { latitude: 32.554, longitude: -85.036, zoom: 8 },
    DUKE: { latitude: 35.14, longitude: -79.54, zoom: 8 }
};
export const getDefaultCoordinatesAndZoomOverrideWithQueryParams = (
    selectedRegion: RegionType,
    maybeScreeningViewData: ScreeningView | undefined,
    queryParams: URLSearchParams
): CoordinatesAndZoom => {
    let defaultCoordinates: CoordinatesAndZoom =
        REGIONS_TO_DEFAULT_COORDINATES[selectedRegion];
    if (maybeScreeningViewData) {
        // There should always be at least one state, but just to be safe we do a check.
        const maybeFirstState = maybeScreeningViewData.states.find(() => true);
        if (maybeFirstState) {
            defaultCoordinates = STATE_TO_DEFAULT_COORDINATES[maybeFirstState];
        }
    }

    const maybeCoordinatesFromQueryParams =
        getCoordinatesAndZoomFromQueryParams(queryParams);

    return maybeCoordinatesFromQueryParams === undefined
        ? defaultCoordinates
        : maybeCoordinatesFromQueryParams;
};

const getCoordinatesAndZoomFromQueryParams = (
    queryParams: URLSearchParams
): CoordinatesAndZoom | undefined => {
    const maybeLatitude = queryParams.get(QUERY_PARAMS_FOR_MAP_VIEW.latitude);
    const maybeLongitude = queryParams.get(QUERY_PARAMS_FOR_MAP_VIEW.longitude);
    const maybeZoom = queryParams.get(QUERY_PARAMS_FOR_MAP_VIEW.zoom);

    if (
        maybeLatitude !== null &&
        maybeLongitude !== null &&
        maybeZoom !== null
    ) {
        const maybeLatitudeAsFloat = parseFloat(maybeLatitude);
        const maybeLongitudeAsFloat = parseFloat(maybeLongitude);
        const maybeZoomAsFloat = parseFloat(maybeZoom);

        if (
            !isNaN(maybeLatitudeAsFloat) &&
            !isNaN(maybeLongitudeAsFloat) &&
            !isNaN(maybeZoomAsFloat)
        ) {
            return {
                latitude: maybeLatitudeAsFloat,
                longitude: maybeLongitudeAsFloat,
                zoom: maybeZoomAsFloat
            };
        }
    }

    return undefined;
};

const isCoordinates = (query: string): boolean => {
    const decimalDegreesPattern = /^[-+]?\d+(\.\d+)?,\s*[-+]?\d+(\.\d+)?$/;
    return decimalDegreesPattern.test(getCleanCoordinates(query));
};

const getCleanCoordinates = (query: string): string => {
    return query.trim().replace(/\s+/g, " ").replace(/°/g, "");
};

// Returns `true` for input like this: 30°49'13.8"N 90°16'42.3"W
const isDmsCoordinate = (query: string): boolean => {
    const re =
        /(\d{1,2}°\d{1,2}'\d{1,2}(?:\.\d+)?"[NS])[\s]+(\d{1,3}°\d{1,2}'\d{1,2}(?:\.\d+)?"[EW])/i;
    return re.test(query);
};

const parseDmsCoordinates = (query: string): Coordinates => {
    const coords = query.split(/\s+/);

    return {
        latitude: parseDmsCoordinate(coords[0]),
        longitude: parseDmsCoordinate(coords[1])
    };
};

const parseDmsCoordinate = (coord: string): number => {
    const direction = coord.slice(-1);
    const degreesMinutesSeconds = coord
        .slice(0, -1)
        .split(/[°'"]/)
        .map(parseFloat);
    const degrees = degreesMinutesSeconds[0];
    const minutes = degreesMinutesSeconds[1];
    const seconds = degreesMinutesSeconds[2];

    let decimalDegrees = degrees + minutes / 60 + seconds / 3600;

    if (
        direction === "S" ||
        direction === "W" ||
        direction === "s" ||
        direction === "w"
    ) {
        decimalDegrees *= -1;
    }

    return round(decimalDegrees, 4);
};

// If input is a DMS coordinate, parses and converts into a decimal (lat/long) coordinate
const maybeParseDmsCoordinates = (query: string): Coordinates | undefined => {
    if (!isDmsCoordinate(query)) {
        return undefined;
    }

    // Protect against any parsing errors
    try {
        return parseDmsCoordinates(query);
    } catch (e) {
        console.error("Failed to parse DMS coordinate", e);
        return undefined;
    }
};

export const maybeGetCoordinates = (query: string): Coordinates | undefined => {
    if (isCoordinates(query)) {
        const cleanCoordinates = getCleanCoordinates(query);
        const coordinates = cleanCoordinates.split(",");
        // Technically, this would all be caught by the regex in isCoordinates
        // but we are being extra safe to avoid runtime errors.
        if (coordinates.length == 2) {
            const latitude = parseFloat(coordinates[0]);
            const longitude = parseFloat(coordinates[1]);
            if (!isNaN(latitude) && !isNaN(longitude)) {
                return {
                    latitude,
                    longitude
                };
            }
        }
    }

    const maybeDmsCoordinate = maybeParseDmsCoordinates(query);
    if (maybeDmsCoordinate) {
        return maybeDmsCoordinate;
    }

    return undefined;
};

export const getFormattedCoordinates = (coordinates: Coordinates): string =>
    `${coordinates.latitude}, ${coordinates.longitude}`;
