import { isStringArray } from "graphql/helpers/queryHelpers";

export const LOAD_VIEW_ENABLED = "load_view_enabled";

export const FEATURE_FLAG_TYPES = [LOAD_VIEW_ENABLED] as const;
export type FeatureFlagType = (typeof FEATURE_FLAG_TYPES)[number];

export const isKnownFeatureFlagType = (
    type: string
): type is FeatureFlagType => {
    return FEATURE_FLAG_TYPES.includes(type as FeatureFlagType);
};

export const convertToFeatureFlagType = (string: string): FeatureFlagType => {
    if (isKnownFeatureFlagType(string)) {
        return string;
    }

    throw new Error("Unexpected feature flag type");
};

export const convertToFeatureFlagTypeList = (
    hasuraObject: unknown
): ReadonlyArray<FeatureFlagType> => {
    if (!isStringArray(hasuraObject)) {
        throw new Error("Expected array of strings");
    }

    const listOfStrings: string[] = hasuraObject;
    const validFeatureFlags: FeatureFlagType[] = listOfStrings
        .filter(isKnownFeatureFlagType)
        .map(convertToFeatureFlagType);

    return validFeatureFlags;
};
